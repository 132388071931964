:root {
  --primary-color: #000000;
  --primary-text-color: #000000;
  --error-color: #ba1a1a;
  --link-color: #8200ff;
}

* {
  margin: 0;
  padding: 0;
  outline: 0 !important;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-display: swap;
}

table {
  width: 100%;
}

a {
  color: var(--mdc-text-button-label-text-color);
}

a,
p,
i {
  font-size: 0.9rem;
}

h5 {
  margin-bottom: 2px;
}

textarea {
  min-height: 19px;
}

mat-card {
  padding: 20px 25px;
}

mat-form-field {
  width: 100% !important;

  mat-error:has(span) {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .mat-mdc-icon-button {
    margin-right: 5px;
  }
}

mat-sidenav-container {
  --mat-sidenav-content-background-color: #f7f2f7;
}

.mat-mdc-paginator-page-size-select {
  width: 84px !important;
}

.tooltip-error-message .mdc-tooltip__surface {
  max-width: unset !important;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: 1em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  box-shadow: 0 0 0 1000 transparent inset;
  -webkit-box-shadow: 0 0 0 1000 transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
  margin: 11.5px !important;
}

.mat-mdc-dialog-title {
  font-size: 1.2rem !important;
}

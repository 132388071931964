@use "@angular/material" as mat;

@include mat.core();

$pedisy-light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$violet-palette,
    ),
    typography: (
      brand-family: "Poppins",
    ),
    density: (
      scale: -2,
    ),
  )
);

html {
  @include mat.core-theme($pedisy-light-theme);
  @include mat.all-component-themes($pedisy-light-theme);
  @include mat.color-variants-backwards-compatibility($pedisy-light-theme);
  @include mat.form-field-density(-3);

  .mat-primary.mat-mdc-button-base {
    --mdc-filled-button-container-color: black;
  }
}

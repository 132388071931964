ng-select {
    &.ng-select-focused {
        .ng-select-container {

            &:after,
            &:hover:after {
                border-color: var(--primary-color) !important;
            }

            .ng-arrow-wrapper .ng-arrow,
            .ng-value-container .ng-placeholder {
                color: var(--primary-text-color) !important;
            }

            // Focused placeholder background
            .ng-value-container .ng-placeholder {
                background-color: white !important;
            }
        }
    }

    // Default placeholder background
    .ng-select-container {
        border-color: var(--primary-color) !important;

        &.ng-has-value {
            .ng-value-container .ng-placeholder {
                background-color: white !important;
            }
        }

        .ng-value-container .ng-placeholder {
            background-color: transparent !important;
        }

        &.ng-appearance-outline {
            min-height: 50px !important;

            &:after {
                border-color: #49454e !important;
            }
        }
    }

    // Background of selected item
    &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        background-color: var(--primary-color) !important;
    }

    // Error colors on border and text
    &.ng-invalid.ng-touched {
        .ng-select-container {

            &:after,
            &:hover:after {
                border: 1px solid var(--error-color);
            }
        }

        .ng-arrow-wrapper .ng-arrow,
        .ng-select-container .ng-value-container .ng-placeholder {
            color: var(--error-color);
        }
    }
}

.ng-dropdown-panel {
    .ng-dropdown-panel-items {

        .ng-optgroup.ng-option-selected,
        .ng-option.ng-option-selected {
            color: var(--primary-text-color) !important;
        }
    }
}